import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { CompanyChanged, CompanyChangedStateService } from '@dougs/core/socket';
import { VendorInvoiceOperationsStateService } from '@dougs/operations/shared';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { VendorInvoiceStateService } from '@dougs/vendor-invoice/shared';

@Injectable({ providedIn: 'root' })
export class VendorInvoiceSocketService {
  constructor(
    private readonly vendorInvoiceStateService: VendorInvoiceStateService,
    private readonly companyChangedStateService: CompanyChangedStateService,
    private readonly vendorInvoiceOperationsStateService: VendorInvoiceOperationsStateService,
    private readonly route: ActivatedRoute,
  ) {}

  updateVendorInvoiceFromSocket$: Observable<void> = this.companyChangedStateService.companyChanged$.pipe(
    concatMap((companyChanged) => this.updateVendorInvoiceFromSocket(companyChanged)),
  );

  private async updateVendorInvoiceFromSocket(companyChanged: CompanyChanged): Promise<void> {
    if (companyChanged.modelName === 'vendorInvoice' && companyChanged.type !== 'delete') {
      const vendorInvoice: VendorInvoice | null = await this.vendorInvoiceStateService.updateVendorInvoiceFromSocket(
        companyChanged.companyId,
        companyChanged.modelId.toString(),
      );
      const vendorInvoiceQueryParams: string | null = this.route.snapshot.queryParamMap.get('vendorInvoiceId');
      const vendorInvoiceDrawerOpened: boolean = vendorInvoiceQueryParams === vendorInvoice?.id;
      if (vendorInvoiceDrawerOpened && vendorInvoice) {
        await this.vendorInvoiceOperationsStateService.refreshOperations(vendorInvoice);
      }
      if (vendorInvoice?.matchedOperation?.id) {
        await this.vendorInvoiceOperationsStateService.refreshMatchedOperation(
          vendorInvoice.companyId,
          vendorInvoice.matchedOperation.id,
        );
      } else {
        this.vendorInvoiceOperationsStateService.resetMatchedOperation();
      }
      if (vendorInvoice?.accrualOperationAttachment?.operation?.id) {
        await this.vendorInvoiceOperationsStateService.refreshAccrualOperation(
          vendorInvoice.companyId,
          vendorInvoice?.accrualOperationAttachment.operation.id,
        );
      } else {
        this.vendorInvoiceOperationsStateService.resetAccrualOperation();
      }
    }
  }
}
